import React, { useState } from "react";
import { Grid, Button, Typography, Box, Divider, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Card from "./Card";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import BuildingModel from '../../model/building';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';

const GET_DOUBLE_SENSORS = gql`
  mutation getDoubleSensors {
    doubleSensors {
      id
      actual_serial_number
      type
      device_type
      building {
        external_id
        street
        house_number
        city
        postalcode
      }
      manufacturer
      created_at
    }
  }
`;

function isValidNumber(value) {
  return typeof value === 'number' && Number.isFinite(value);
}

export default function ImportIssues({ data }) {
  const { t } = useTranslation(["dashboard"]);
  const [getDoubleSensors] = useMutation(GET_DOUBLE_SENSORS);
  const [sensorConflictsLoading, setDoubleSensorsLoading] = useState(false);

  const handleDoubleSensorsDownload = async () => {
    setDoubleSensorsLoading(true);
    try {
      const { data: getDoubleSensorsData } = await getDoubleSensors();

      const formattedData = getDoubleSensorsData.doubleSensors.map(({ actual_serial_number, type, device_type, created_at, manufacturer, building }) => ({
        "Device Serial Number": actual_serial_number,
        "Type": type,
        "Device Type": device_type,
        "Created At": created_at,
        "Device Manufacturer": manufacturer,
        "Building External Number": building.external_id,
        "Building Address": BuildingModel.fullAddress(
          building.street,
          building.house_number,
          building.city,
          building.postalcode
        ),
      }));

      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Import Issues');

      // Generate a binary string representation of the workbook
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Convert the binary string to a Blob and trigger the download
      const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(dataBlob, "DoubleSensors-"+((new Date()).toISOString())+".xlsx");
    } catch (error) {
      console.error("Error fetching import issues data:", error);
    } finally {
      setDoubleSensorsLoading(false);
    }
  };

  return (
    <Grid item xs={12} md={4}>
      <Card title={t("dashboard:Import Issues")}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          p={2}
        >
          <Box>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {t("dashboard:Double Serial Numbers")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t("dashboard:Number of Devices")}: {isValidNumber(data.sensor_conflicts_count) ? data.sensor_conflicts_count : 'N/A'}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDoubleSensorsDownload}
            endIcon={sensorConflictsLoading ? <CircularProgress size={24} /> : <DownloadIcon />}
            disabled={sensorConflictsLoading || !isValidNumber(data.sensor_conflicts_count) || data.sensor_conflicts_count === 0}
          >
            {sensorConflictsLoading ? t("dashboard:loading") : t("dashboard:excel")}
          </Button>
        </Box>

      </Card>
    </Grid>
  );
}
